<template>
    <div style="height: 100%;">
      <v-md-editor
        class="markdown"
        v-model="content_"
        :mode="mode"
        :tab-size="tabSize"
        :toc-nav-position-right="tocNavPositionRight"
        :default-show-toc="defaultShowToc"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :default-fullscreen="defaultFullscreen"
        :include-level="includeLevel"
        :left-toolbar="leftToolbar"
        :right-toolbar="rightToolbar"
        :toolbar-config="toolbarConfig"
        :toolbar="toolbar"
        :disabled-menus="disabledMenus"
        :upload-image-config="uploadImageConfig"
        :before-preview-change="beforePreviewChange"
        :codemirror-config="codemirrorConfig"
        :codemirror-style-reset="codemirrorStyleReset"
        @upload-image="handleUploadImage"
        @copy-code-success="handleCopyCodeSuccess"
        @change="change"
        height="100%"
      ></v-md-editor>
    </div>
  </template>

<script>
export default {
  name: 'MdEditor',
  props: {
    mode: {
      type: String,
      default: 'editable'
    },
    content: {
      type: String,
      default: ''
    }
  },
  watch: {
    // 等待父组件准备数据
    content (newVal) {
      this.content_ = newVal
    }
  },
  data () {
    // const vm = this
    return {
      tabSize: 2, // 制表符长度
      defaultShowToc: false, // 是否默认显示目录导航
      tocNavPositionRight: false, // 目录导航是否在右侧,
      placeholder: '请使用markdown语法编写', // 提示信息
      autofocus: false, // 编辑器加载完是否自动聚焦
      defaultFullscreen: false, // 是否默认开启全屏
      includeLevel: [1, 2, 3], // 目录导航生成时包含的标题。默认包含 2 级、3 级标题。
      leftToolbar:
          'h bold italic strikethrough quote | ul ol table hr | link image code | undo redo clear', // 左侧工具栏
      rightToolbar: 'preview toc sync-scroll fullscreen', // 右侧工具栏
      // 内置 toolbar 的一些配置
      toolbarConfig: {
        'image-link': {
          // 是否在插入图片链接的时候默认带上width height 属性，默认值：false
          insertWithSize: false
        }
      },
      disabledMenus: [], // 禁用菜单  ['image/upload-image',h/h1]
      uploadImageConfig: { accept: 'image/*' }, // 上传本地图片的相关配置
      codemirrorConfig: {}, // 初始化 Codemirror 的配置。将会与内部的默认的配置合并
      codemirrorStyleReset: true, // 是否对 codemirror 编辑器的部分样式进行优化。若关闭，则会恢复 codemirror 编辑器默认的样式
      // 自定义工具栏
      toolbar: {
        export: {
          title: '导出',
          icon: 'bx bxs-file-export',
          action (editor) {
          }
        }
      },

      content_: this.content
    }
  },
  methods: {
    // 在预览解析前触发的函数，配置此属性后必须要调用 next 方法并传入需要渲染的 md 文本，否则 preview 组件将不会进行渲染。
    beforePreviewChange (text, next) {
      next()
    },
    change (text, html) {
      this.$emit('getNewHtml', html)
    },
    handleUploadImage (event, insertImage, files) {
      insertImage({
        url: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1269952892,3525182336&fm=26&gp=0.jpg',
        desc: '七龙珠',
        width: 'auto',
        height: 'auto'
      })
    },
    // eslint-disable-next-line no-unused-vars
    handleCopyCodeSuccess (code) {},
    test () {
    }
  },

  created () {
    this.content_ = this.content
  }
}
</script>

  <style  lang="scss">
  .markdown {
    .v-md-editor__toolbar-item {
      font-size: 20px !important;
    }
  }

  </style>
