<template>
    <div class="scorllBox">
      <div @mouseenter="on_top_enter" @mouseleave="on_top_leave">
        <swiper
            :options="swiperOption()"
            ref="mySwiper"
        >
          <swiper-slide
            :key="index"
            v-for="(post, index) in posts"
          >
            <PostCard :that="that" :post="post" class="center" />
          </swiper-slide>
        </swiper
        >
      </div>
    </div>
  </template>

<script>
import PostCard from '@/components/PostCard.vue'
export default {
  components: {
    PostCard
  },
  props: ['posts', 'that', 'deviceType'],
  // 计算属性，获得可以操作的swiper对象
  computed: {
    mySwiper () {
      // mySwiper 是要绑定到标签中的ref属性
      return this.$refs.mySwiper.swiper
    }
  },
  data () {
    return {
      slidesPerView: 1
    }
  },
  methods: {
    on_top_enter () {
      this.mySwiper.autoplay.stop()
      this.mySwiper.setTranslate(this.mySwiper.getTranslate())// 鼠标移入立刻停止滚动
    },
    on_top_leave () {
      this.mySwiper.autoplay.start()
    },
    swiperOption () {
      return {
        notNextTick: true,
        direction: 'horizontal',
        speed: 4000, // 滚动速度
        autoplay: {
          delay: 1,
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
          waitForTransition: true// 等待过渡完毕。自动切换会在slide过渡完毕后才开始计时
        },
        simulateTouch: false, // 设置鼠标拖动无效
        freeMode: true, // free模式，slide会根据惯性滑动且不会贴合
        // mousewheel: {
        //   sensitivity: 2// 滚动一次切换几个slide
        // },
        loop: true, // 循环
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        spaceBetween: 0, // slide之间的距离（单位px）
        slidesPerView: this.slidesPerView // slide可见数量，30: 行高
      }
    }
  },
  created () {
    if (this.deviceType === 'pc') {
      this.slidesPerView = 4
    } else if (this.deviceType === 'pad') {
      this.slidesPerView = 2
    } else if (this.deviceType === 'mobile') {
      this.slidesPerView = 1
    }
  }
}
</script>

  <style scoped>
  .scorllBox {
    overflow: hidden;
  }

  </style>
