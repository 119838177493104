<!-- CommentReply.vue -->
<template>
    <div>
        <div :class="(reply.grade === 0 || reply.grade === 1) ? 'c_item' : 'c_item_nomargin' " v-for="(reply, index) in replies" :key="index">
          <div class="c_reply_content">
            <img class="c_avatar" src="http://192.168.10.90:9000/vueblogger_plus/test/微信图片_20240301203734.jpg" width="36" height="36" style="border_radius: 50%; margin_right: 10px;" />
            <span class="c_from_name">{{ reply.from_user_name }}</span><span>: </span>
            <span class="c_to_name" v-if="reply.grade !== 0 && reply.grade !== 1">@{{ reply.to_user_name }}</span>
            <!-- 评论图片 -->
            <div class="grid-10_xs-1_sm-2_md-2">
              <div class="col">
                <ImageViewer :images="['http://localhost:9000/vueblogger-plus/test/微信截图_20240229151302.png']"></ImageViewer>
              </div>
            </div>
            <span>{{ reply.content }}</span>
          </div>
          <div class="c_reply_bottom">
            <span>{{ reply.record_time }}</span>
            <!-- <span class="c_reply-text" @click="showCommentInput(nowItem, reply)">
              <i class="c_iconfont c_icon-comment"></i>
              <span>回复</span>
            </span> -->
            <span class="c_reply_text" @click="showCommentInput(nowItem, reply)">
              <CommentBtn :btnText="'回复层主'"></CommentBtn>
            </span>
          </div>
          <div :class="(reply.grade === 0) ? 'c_reply' : '' ">
            <transition name="fade">
                <div class="c_input_wrapper" v-if="showItemId === reply.id">
                    <div style="position: relative;">
                      <vs-input type="textarea" :rows="3" autofocus :placeholder="placeHolder" v-model="inputComment"></vs-input>
                      <i class='bx bxs-image-add' style="position: absolute; bottom: 22px; right: 15px;"></i>
                      <i class='bx bx-smile' style="position: absolute; bottom: 22px; right: 40px;"></i>
                    </div>
                    <div class="c_btn_control">
                        <span class="c_cancel" @click="cancel">取消</span>
                        <vs-button class="c_btn" type="success" round @click="commitComment">确定</vs-button>
                    </div>
                </div>
            </transition>
          </div>
          <!-- 递归调用组件显示下一级回复 -->
          <CommentChild :placeHolder="placeHolder" :showItemId="showItemId" :that="that" :replies="reply.children" :nowItem="reply" @showCommentInput="showCommentInput" @cancel="cancel" @commitComment="commitComment" v-if="reply.children && reply.children.length > 0" />
        </div>
    </div>
  </template>

<script>
import ImageViewer from '@/components/ImageViewer.vue'
import CommentBtn from '@/components/CommentBtn.vue'
export default {
  name: 'CommentChild',
  components: {
    ImageViewer,
    CommentBtn
  },
  props: ['that', 'replies', 'nowItem', 'showItemId', 'placeHolder'],
  data () {
    return {
      inputComment: ''
    }
  },
  methods: {
    // 调用父组件的方法
    commitComment (inputCommentFromReply) {
      if (inputCommentFromReply) {
        this.$emit('commitComment', inputCommentFromReply)
      } else {
        this.$emit('commitComment', this.inputComment)
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    showCommentInput (nowItem, reply) {
      this.$emit('showCommentInput', nowItem, reply)
    }
  }
}
</script>
<style scoped>
.c_comment .c_reply .c_item {
    margin: 0 10px;
    padding: 10px 0;
}
.c_comment .c_reply .c_item_nomargin {
    padding: 10px 0;
}

.c_comment .c_reply .c_item .c_reply_content {
    align-items: center;
    font-size: 14px;
    color: var(--font-color);
}

.c_comment .c_info .c_avatar {
    border-radius: 50%;
}

.c_comment .c_reply .c_item .c_reply_content .c_from_name {
    color: #409EFF;
}

.c_comment .c_reply .c_item .c_reply_content .c_to_name {
    color: #409EFF;
    margin-left: 5px;
    margin-right: 5px;
}

.c_comment .c_reply .c_item .c_reply_bottom {
    align-items: center;
    margin-top: 6px;
    font-size: 12px;
    color: #909399;
}

.c_comment .c_reply .c_item .c_reply_bottom .c_reply_text {
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}

.c_comment .c_reply .c_item .c_reply_bottom .c_reply_text .c_icon_comment {
    margin-right: 5px;
}

.c_comment .c_reply .c_item_nomargin .c_reply_bottom .c_reply_text {
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.c_comment .c_reply .c_item_nomargin .c_reply_bottom .c_reply_text .c_icon_comment {
    margin-right: 5px;
}

.c_comment .c_reply .c_input_wrapper {
    padding: 10px;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control .c_cancel {
    font-size: 16px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control .c_confirm {
    font-size: 16px;
}

.c_comment .c_reply .c_write_reply {
    align-items: center;
    font-size: 14px;
    color: #909399;
    padding: 10px 10px 10px 0;
    cursor: pointer;
}
</style>
