<template>
  <div style="position: relative;">
    <div class="container" ref="container">
      <div class="text-center">
        <h1 class="headline">{{ this.postData.title }}</h1>
        <p style="font-weight: bold;">
          Posted on: {{ this.postData.record_time }}
        </p>
        <p style="font-weight: bold;" v-if="this.postData.update_time">
          Update on: {{ this.postData.update_time }}
        </p>
      </div>
      <hr />

      <MdEditor :mode="'preview'" :content="this.postData.content"></MdEditor>

      <h2>Comments</h2>
      <Comment style="width: 90vw; margin: 0 auto" :comments="this.comments" :that="that" @commitComment="commitComment" ref="comment"></Comment>
      <div style="margin: 30px auto;">
        <vs-pagination not-margin v-model="page" :length="20" />
      </div>

      <h2>Series/Related</h2>
      <hr/>
      <PostCardScorll :posts="posts" :that="that" :deviceType="deviceType"></PostCardScorll>

      <PostTags :postTags="this.postData.tags" />
      <PostNavBtn :prev="this.prePost" :next="this.nextPost" @getNowPostId="getNowPostId"/>

    </div>

  </div>
</template>

<script>
import PostNavBtn from '@/components/PostNavBtn.vue'
import PostTags from '@/components/PostTags.vue'
import Comment from '@/components/Comment.vue'
import MdEditor from '@/components/MdEditor.vue'
// import io from 'socket.io-client'
import PostCardScorll from '@/components/PostCardScorll.vue'
export default {
  name: 'ViewPost',
  components: {
    PostNavBtn,
    PostTags,
    Comment,
    MdEditor,
    PostCardScorll
  },
  data () {
    return {

      postId: this.$route.params.postId,
      postData: {
        id: 'test_p',
        title: '--',
        description: '这是描述',
        record_time: '--',
        update_time: '--',
        content: '',
        tags: ['标签1', '标签2']
      },
      comments: [],
      prePost: {
        title: null,
        id: null
      },
      nextPost: {
        title: null,
        id: null
      },

      that: this,

      deviceType: 'pc',

      page: 1,

      posts: this.getConfig('posts.json').posts
    }
  },
  methods: {
    getNowPostId (postId) {
      this.postId = postId
    },
    // 提交评论
    commitComment (newComment) {
      const sensitiveParam = {}
      sensitiveParam.content = newComment.content
      sensitiveParam.userId = '1'
      sensitiveParam.userIp = '127.0.0.1'
      sensitiveParam.file = newComment.file
      this.request.POST('/sensitive/check', sensitiveParam, 'multipart/form-data', 'http://localhost:83/helper')
        .then(res => {
          if (res.code === 0) {
            this.request.POST('/comment/add', newComment, 'multipart/form-data')
              .then((res) => {
                if (res.code === 0) {
                  this.common.showNotification('success', 'request success', '评论成功')
                  // 强制刷新评论组件的内容
                  this.getComments()
                }
              })
          }
        })
    },
    // 获取评论
    getComments () {
      this.request
        .GET('/comment/getCommentsByPostId', {
          postId: this.postId
        })
        .then((res) => {
          this.comments = res.data
          // 强制刷新子组件的内容(将comments重新传到子组件),评论之后需要此步,初始加载数据此代码不影响数据渲染
          this.$refs.comment.$forceUpdate()
          // 初始化子组件的data中的数据
          this.$refs.comment.initData()
        })
    },
    // 获取上一条和下一条博客的信息
    getPostPreAndNext () {
      this.request
        .GET('/post/getPreAndNext', {
          postId: this.postId
        })
        .then((res) => {
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].pre_next === 'pre') {
              this.prePost.id = res.data[i].id
              this.prePost.title = res.data[i].title
            } else {
              this.nextPost.id = res.data[i].id
              this.nextPost.title = res.data[i].title
            }
          }
        })
    },
    // 获取博客内容
    getPost () {
      this.request
        .GET('/post/get', {
          postId: this.postId
        })
        .then((res) => {
          this.postData = res.data
        })
    },
    // 初始化数据
    initData () {
      this.postData = {
        title: '--',
        record_time: '--',
        update_time: '--',
        content: '',
        tags: []
      }
      this.comments = []
      this.prePost = {
        title: null,
        id: null
      }
      this.nextPost = {
        title: null,
        id: null
      }
      this.that = this

      this.$refs.comment.$forceUpdate()
      this.$refs.comment.initData()
    }
  },
  // 在路由更新前重新加载数据
  beforeRouteUpdate (to, from, next) {
    this.initData()

    this.getPost()
    this.getComments()
    this.getPostPreAndNext()

    next()
  },
  mounted () {
    this.initData()

    // 获取post的内容
    if (this.postId !== undefined) {
      this.getPost()
      this.getComments()
      this.getPostPreAndNext()
    }

    // 监听文章内容的变化
    // const socket = io('http://localhost:60000')
    // socket.on('update_post_msg', (data) => {
    //   if (data === this.postId) {
    //     this.common.showNotification('success', 'post updated', '该文章内容更新')
    //     this.getPost()
    //   }
    // })

    this.common.eventBus().$on('scrollToTop', () => {
      this.$refs.container.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  },
  created () {
    this.deviceType = this.common.getDeviceType()
  }
}
</script>

<style scoped>
.cover {
  /* width: 100vw; */
  width: 55vw;
  height: auto;
  display: block;
  margin: 0 auto;
}

.margin {
  margin-left: 20px;
}

.post img {  /* limit image max width to 100vw in a post */
  max-width: 100%;
}
</style>
