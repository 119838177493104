<template>
    <viewer :images="images">
      <img
        v-for="(item, index) in images"
        :key="index"
        :src="item"
        alt=""
        style="height: 100%; width: 100%; border-radius: 10%; object-fit: cover;"
        >
    </viewer>
  </template>
<script>
export default {
  name: 'ImageViewer',
  props: ['images'],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {}
}
</script>
  <style scoped>
  </style>
