<!--评论模块-->
<template>
    <div>
        <!-- <div class="c_scrollable"> -->
        <div>
            <!-- 新增文章评论 -->
            <div class="c_write_reply" @click="showCommentInput(null, null)">
                <CommentBtn :btnText="'添加文章评论'"></CommentBtn>
            </div>
            <transition name="fade">
                <div class="c_input_wrapper" v-if="showItemId === postId" style="position: relative;">
                    <!-- 操作输入 -->
                    <div style="position: relative;">
                        <!-- 表情,暂不使用 -->
                        <!-- <vs-button circle icon style="position: absolute; margin: auto; top: 50%; transform: translateY(-50%); left: 5px; cursor: pointer; width: 25px; height: 25px;" @click="showOrHideEmojiDialog">
                            <i class='bx bx-smile btn-emoji'></i>
                        </vs-button> -->
                        <!-- 上传图片按钮 -->
                        <vs-button circle icon style="position: absolute; margin: auto 0; top: 50%; transform: translateY(-50%); left: 5px; cursor: pointer; width: 25px; height: 25px;" @click="upload">
                            <i class='bx bxs-image-add btn-upload'></i>
                        </vs-button>
                        <!-- 文本 -->
                        <vs-input success shadow v-model="inputComment" type="text" autofocus
                            :placeholder="placeHolder" style="padding-left: 40px;" ref="newCommentToPost" @click="handleCursorChange">
                        </vs-input>
                    </div>
                    <!-- 图片预览 -->
                    <div v-if="commentFileFlag" style="position: relative; margin-top: 10px;">
                        <div style="position: absolute; top: -10px; left: 90px; width: 20px; height: 20px; cursor: pointer;" @click="cancelUpload">
                            <svg style="width: 100%; height: 100%;" t="1711370615713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5359" width="32" height="32"><path d="M512 0c282.7648 0 512 229.2352 512 512s-229.2352 512-512 512S0 794.7648 0 512 229.2352 0 512 0z m-1.4848 450.978133L341.162667 281.6256a34.133333 34.133333 0 0 0-48.273067 0l-11.264 11.264a34.133333 34.133333 0 0 0 0 48.273067l169.352533 169.352533-169.352533 169.352533a34.133333 34.133333 0 0 0 0 48.273067l11.264 11.264a34.133333 34.133333 0 0 0 48.273067 0l169.352533-169.3568 169.352533 169.3568a34.133333 34.133333 0 0 0 48.273067 0l11.264-11.264a34.133333 34.133333 0 0 0 0-48.273067l-169.3568-169.352533 169.3568-169.352533a34.133333 34.133333 0 0 0 0-48.273067l-11.264-11.264a34.133333 34.133333 0 0 0-48.273067 0l-169.352533 169.352533z" fill="#909090" p-id="5360"></path></svg>
                        </div>
                        <ImageViewer :images="commentFilePreviewImage" style="border-radius: 10px; width: 100px; height: 100px;"></ImageViewer>
                    </div>
                    <!-- 表情大全 -->
                    <div v-if="commentEmojiFlag" style="position: absolute; left: 15px; top: 55px; z-index: 5;">
                        <VEmojiPicker :showSearch="false" @select="selectEmoji"></VEmojiPicker>
                    </div>
                    <!-- 操作按钮 -->
                    <div class="c_btn_control">
                        <vs-button class="c_cancel" danger round @click="cancel">取消</vs-button>
                        <vs-button class="c_btn" primary round @click="commitComment(null)">确定</vs-button>
                    </div>
                </div>
            </transition>

            <div class="c_comment" v-for="(item, index) in comments" :key="index">
                <div class="c_info">
                    <!-- <img class="c_avatar" :src="item.from_user_avatar_url" width="36" height="36" /> -->
                    <img class="c_avatar" :src="'http://192.168.10.90:9000/vueblogger-plus/config/black_cat.jpg'" width="36" height="36" />
                    <div class="c_right">
                        <div class="c_name">{{ item.from_user_name }} </div>
                        <div class="c_date">{{ item.record_time }}&nbsp;&nbsp;&nbsp;&nbsp;IP属地: <b>烟台</b></div>
                    </div>
                </div>
                <!-- 评论图片 -->
                <div class="grid-10_xs-1_sm-2_md-2">
                    <div class="col">
                        <ImageViewer></ImageViewer>
                    </div>
                </div>
                <!-- <div class="c_content">{{ item.content }}</div> -->
                <div style="display: flex; justify-content: space-between;">
                    <div >{{ item.content }}</div>
                    <div style="width: 30px; height: 30px;">
                        <BtnLike :like="item.like_flag === 'Y'"></BtnLike>
                    </div>
                </div>
                <div class="c_write_reply" @click="showCommentInput(item)">
                    <CommentBtn :btnText="'回复楼主'"></CommentBtn>
                </div>
                <transition name="fade">
                    <div class="c_input_wrapper" v-if="showItemId === item.id">
                        <div style="position: relative;">
                            <!-- 上传图片按钮 -->
                            <vs-button circle icon style="position: absolute; margin: auto 0; top: 50%; transform: translateY(-50%); left: 5px; cursor: pointer; width: 25px; height: 25px;" @click="upload">
                                <i class='bx bxs-image-add btn-upload'></i>
                            </vs-button>

                            <vs-input v-model="inputComment" type="textarea" :rows="3" autofocus
                                :placeholder="placeHolder" style="padding-left: 40px;">
                            </vs-input>
                        </div>
                        <!-- 图片预览 -->
                        <div v-if="commentFileFlag" style="position: relative; margin-top: 10px;">
                            <div style="position: absolute; top: -10px; left: 90px; width: 20px; height: 20px; cursor: pointer;" @click="cancelUpload">
                                <svg style="width: 100%; height: 100%;" t="1711370615713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5359" width="32" height="32"><path d="M512 0c282.7648 0 512 229.2352 512 512s-229.2352 512-512 512S0 794.7648 0 512 229.2352 0 512 0z m-1.4848 450.978133L341.162667 281.6256a34.133333 34.133333 0 0 0-48.273067 0l-11.264 11.264a34.133333 34.133333 0 0 0 0 48.273067l169.352533 169.352533-169.352533 169.352533a34.133333 34.133333 0 0 0 0 48.273067l11.264 11.264a34.133333 34.133333 0 0 0 48.273067 0l169.352533-169.3568 169.352533 169.3568a34.133333 34.133333 0 0 0 48.273067 0l11.264-11.264a34.133333 34.133333 0 0 0 0-48.273067l-169.3568-169.352533 169.3568-169.352533a34.133333 34.133333 0 0 0 0-48.273067l-11.264-11.264a34.133333 34.133333 0 0 0-48.273067 0l-169.352533 169.352533z" fill="#909090" p-id="5360"></path></svg>
                            </div>
                            <ImageViewer :images="commentFilePreviewImage" style="border-radius: 10px; width: 100px; height: 100px;"></ImageViewer>
                        </div>
                        <div class="c_btn_control">
                            <vs-button class="c_cancel" danger round @click="cancel">取消</vs-button>
                            <vs-button class="c_btn" primary round @click="commitComment(null)">确定</vs-button>
                        </div>
                    </div>
                </transition>
                <div class="c_reply">
                    <CommentChild :placeHolder="placeHolder" :showItemId="showItemId" :that="that" :replies="item.children" :nowItem="item" @showCommentInput="showCommentInput" @cancel="cancel" @commitComment="commitComment" v-if="item.children.length && item.children.length > 0"></CommentChild>
                </div>
            </div>

        </div>

        <!-- 上传图片的input -->
        <div>
            <input type="file" id="commentFile" style="display: none;" @change="change">
        </div>
    </div>
</template>

<script>
import CommentChild from '@/components/CommentChild.vue'
import ImageViewer from '@/components/ImageViewer.vue'
import CommentBtn from '@/components/CommentBtn.vue'
import BtnLike from '@/components/BtnLike.vue'
import { VEmojiPicker } from 'v-emoji-picker'
export default {
  components: {
    CommentChild,
    ImageViewer,
    CommentBtn,
    BtnLike,
    VEmojiPicker
  },
  props: ['comments', 'that'],
  data () {
    return {
      inputComment: '',
      showItemId: '',
      replyedCommentId: '',
      replyedUserId: '',
      commentFileFlag: false,
      commentEmojiFlag: false,
      commentFilePreviewImage: [],
      file: {},

      placeHolder: '请写下你的评论',

      postId: this.$route.params.postId,

      startPos: null
    }
  },
  computed: {},
  methods: {
    handleCursorChange () {
      this.startPos = this.$refs.newCommentToPost.$el.querySelector('input').selectionStart
    },
    selectEmoji (emoji) {
      let text = ''
      if (this.startPos === 0) {
        text = emoji.data + this.inputComment
      } else if (this.startPos === this.inputComment.length) {
        text = this.inputComment + emoji.data
      } else {
        text = this.inputComment.substring(0, this.startPos) + emoji.data + this.inputComment.substring(this.startPos)
      }
      this.inputComment = text

      // emoji的长度是2
      this.startPos += 2

      // this.$refs.xxx获取的是实例的引用
      // .$el将获取的DOM元素转成原生的DOM元素
      // .querySelector('input')是获取的真正的input元素
      var input = this.$refs.newCommentToPost.$el.querySelector('input')
      input.focus()
      // 直接给光标定位还不行,得等待文本内容更新以后再定位
      setTimeout(() => {
        input.setSelectionRange(this.startPos, this.startPos)
      }, 0)
    },
    showOrHideEmojiDialog () {
      this.commentEmojiFlag = !this.commentEmojiFlag

      if (this.commentEmojiFlag) {
        var input = this.$refs.newCommentToPost.$el.querySelector('input')
        this.startPos = input.selectionStart

        input.focus()
        input.setSelectionRange(this.startPos, this.startPos)
      }
    },
    cancelUpload () {
      this.commentFilePreviewImage = []
      this.file = {}
      this.commentFileFlag = false
    },
    upload () {
      document.getElementById('commentFile').click()
    },
    change (e) {
      var file = e.target.files[0]

      this.file = file
      this.commentFileFlag = true

      // 回显图片
      var reader = new FileReader()
      var that = this
      reader.onload = function (e) {
        // document.getElementById('commentFilePreview').setAttribute('src', e.target.result)

        that.commentFilePreviewImage = []
        that.commentFilePreviewImage.push(e.target.result)
      }
      reader.readAsDataURL(file)
    },
    cancel () {
      this.showItemId = ''
    },

    // 提交评论
    commitComment (inputCommentFromReply) {
      // 调用父组件的方法
      var newComment = {}
      newComment.parentId = this.replyedCommentId
      if (inputCommentFromReply) {
        newComment.content = inputCommentFromReply
      } else {
        newComment.content = this.inputComment
      }
      newComment.postId = this.postId
      newComment.toUserId = this.replyedUserId

      //   newComment.file = this.commentFilePreviewImage[0]
      // 传后台file而不是base64的好处是
      // 1.可以在接口中获取name等属性
      // 2.减轻带宽压力
      newComment.file = this.file

      this.$emit('commitComment', newComment)
    },

    /**
     * 点击评论按钮显示输入框
     * item: 当前大评论
     * reply: 当前回复的评论
     */
    showCommentInput (item, reply) {
      this.initData()

      if (reply) {
        this.placeHolder = '@' + reply.from_user_name
        // 被回复的评论id
        this.replyedCommentId = reply.id
        this.replyedUserId = reply.from_user_id

        // 在这个评论的根节点下显示评论框
        this.showItemId = reply.id
      } else {
        if (item) {
          this.placeHolder = '@' + item.from_user_name + ' '
          // 被回复的评论id
          this.replyedCommentId = item.id
          this.replyedUserId = item.from_user_id

          // 在这个评论的根节点下显示评论框
          this.showItemId = item.id
        } else {
          this.placeHolder = '@author' + ' '
          this.replyedCommentId = -1
          this.replyedUserId = -1

          // 在这个评论的根节点下显示评论框
          this.showItemId = this.postId
        }
      }
    },

    // 初始化数据
    initData () {
      this.inputComment = ''
      this.showItemId = ''
      this.replyedCommentId = ''
      this.replyedUserId = ''

      this.placeHolder = '请写下你的评论'

      this.postId = this.$route.params.postId
    }
  }
}
</script>

<style scoped>
.c_scrollable {
    max-height: 600px;
    overflow: auto;
}

.c_scrollable .c_write_reply {
    margin-top: 20px;
    margin-bottom: 25px;
}

.c_scrollable::-webkit-scrollbar {
    display: none;
}

:root {
    --font-color-light: #000;
    --font-color-dark: #FFF;
}

body[vs-theme='light'] {
    --font-color: var(--font-color-light)
}

body[vs-theme='dark'] {
    --font-color: var(--font-color-dark)
}

.c_title {
    color: var(--font-color);
}

.c_comment {
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #F2F6FC;
}

.c_comment .c_info {
    display: flex;
    align-items: center;
}

.c_comment .c_info .c_avatar {
    border-radius: 50%;
}

.c_comment .c_info .c_right {
    flex-direction: column;
    margin-left: 10px;
}

.c_comment .c_info .c_right .c_name {
    font-size: 16px;
    color: var(--font-color);
    margin-bottom: 5px;
    font-weight: 500;
}

.c_comment .c_info .c_right .c_date {
    font-size: 12px;
    color: #909399;
}

.c_comment .c_content {
    font-size: 16px;
    color: var(--font-color);
    line-height: 20px;
    padding: 10px 0;
}

.c_comment .c_control {
    align-items: center;
    font-size: 14px;
    color: #909399;
}

.c_comment .c_control .c_like {
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.c_comment .c_control .c_like.active,
.c_comment .c_control .c_like:hover {
    color: #409EFF;
}

.c_comment .c_control .c_like .c_iconfont {
    font-size: 14px;
    margin-right: 5px;
}

.c_input_wrapper .c_btn_control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.c_comment .c_control .c_comment_reply {
    align-items: center;
    cursor: pointer;
}

.c_comment .c_control .c_comment_reply .c_iconfont {
    font-size: 16px;
    margin-right: 5px;
}

.c_comment .c_reply {
    margin: 10px 0;
    border-left: 2px solid #DCDFE6;
}

/* .c_comment .c_reply .c_item {
    margin: 0 10px;
    padding: 10px 0;
} */

/* .c_comment .c_reply .c_item .c_reply_content {
    align-items: center;
    font-size: 14px;
    color: var(--font-color);
} */

/* .c_comment .c_reply .c_item .c_reply_content .c_from_name {
    color: #409EFF;
}

.c_comment .c_reply .c_item .c_reply_content .c_to_name {
    color: #409EFF;
    margin-left: 5px;
    margin-right: 5px;
} */

/* .c_comment .c_reply .c_item .c_reply_bottom {
    align-items: center;
    margin-top: 6px;
    font-size: 12px;
    color: #909399;
} */

/* .c_comment .c_reply .c_item .c_reply_bottom .c_reply_text {
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}
*/
.c_comment .c_reply .c_item .c_reply_bottom .c_reply_text .c_icon_comment {
    margin-right: 5px;
}

/* .c_comment .c_reply .c_item_nomargin {
    padding: 10px 0;
} */

.c_comment .c_reply .c_item_nomargin .c_reply_content {
    align-items: center;
    font-size: 14px;
    color: var(--font-color);
}

.c_comment .c_reply .c_item_nomargin .c_reply_content .c_from_name {
    color: #409EFF;
}

.c_comment .c_reply .c_item_nomargin .c_reply_content .c_to_name {
    color: #409EFF;
    margin-left: 5px;
    margin-right: 5px;
}

.c_comment .c_reply .c_item_nomargin .c_reply_bottom {
    align-items: center;
    margin-top: 6px;
    font-size: 12px;
    color: #909399;
}

/* .c_comment .c_reply .c_item_nomargin .c_reply_bottom .c_reply_text {
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}*/

.c_comment .c_reply .c_item_nomargin .c_reply_bottom .c_reply_text .c_icon_comment {
    margin-right: 5px;
}

.c_comment .c_reply .c_write_reply {
    align-items: center;
    font-size: 14px;
    color: #909399;
    padding: 10px 10px 10px 0;
    cursor: pointer;
}

.c_comment .c_reply .c_fade_enter_active,
.c_comment .c_reply .c_fade_leave_active {
    transition: opacity 0.5s;
}

.c_comment .c_reply .c_fade_enter,
.c_comment .c_reply .c_fade-leave_to {
    opacity: 0;
}

/* .c_comment .c_reply .c_input_wrapper {
    padding: 10px;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control,
.c_input_wrapper .c_btn_control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control .c_cancel {
    font-size: 16px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
}

.c_comment .c_reply .c_input_wrapper .c_btn_control .c_confirm {
    font-size: 16px;
} */

</style>
