<template>
  <router-link :to="`/post/${post.id}`">
    <vs-card class="post-card">
      <template #title>
        <h3>{{ post.title }}</h3>
        <small>{{ post.record_time }}</small>
      </template>
      <template #img>
        <img :src="'http://www.98qy.com/sjbz/api.php/'+Math.random()" alt @error="setDefaultImage"/>
      </template>
      <template #text>
        <p class="post-txt" v-html="post.description"></p>
        <small class="post-card-tag">
          <b :key="i" v-for="(tag, i) in post.tags" style="margin-right: 5px">
            {{ tag }}
          </b>
        </small>
      </template>
      <template #interactions>
        <vs-tooltip right shadow interactivity>
          <Avatar :avatarUrl = post.blogger_avatar_url />
          <template #tooltip>
            Posted by
            <b>{{ post.record_name }}</b>
          </template>
        </vs-tooltip>
      </template>
    </vs-card>
  </router-link>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import defaultImage from '@/assets/default.png'
export default {
  name: 'PostCard',
  props: ['post'],
  data: function () {
    return {
      config: this.getConfig()
    }
  },
  components: {
    Avatar
  },
  methods: {
    setDefaultImage (e) {
      e.target.src = defaultImage
    }
  },
  created () {
  }
}
</script>

<style>
.post-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: auto;
}

.post-card .vs-card {
  height: 380px !important;
}

.post-card-tag {
  position: absolute;
  bottom: 20px;
}
</style>
